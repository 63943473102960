 /* eslint-disable */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import "./login.css";

export default function Login() {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [userData, setUserData] = useState([])
    const [validatedData, setValidatedData] = useState([])
    const [items, setItems] = useState([])
    const navigate = useNavigate();
    const cookies = new Cookies();
    const handleSubmit = async (e) => {
        e.preventDefault()
        fetch("https://dev.payments.techdogcloud.com/api/v1/user/specific")
        .then(res => res.json())
        .then(data => {
            if(data.filter(data => data.email == email && data.password == password).length == 1) {
                setSession()
            } else {
                console.log("password or email incorrect")
            }
        })
        .then(data => {
            
        })
        .catch(err => console.log(err))
        
        // console.log(userData.filter(data => data.email == email && data.password == password))

    }

    const setSession = (e) => {
        cookies.set('email', email)
        navigate('/dashboard')
    }


    // useEffect(() => {
    //     if(cookies.get('email') != null) {
    //         navigate("/dashboard")
    //     }
    // })

    return (
        <div className="main-box">
            <form onSubmit={e => handleSubmit(e)}>
                <div>
                    <label>Email:</label>
                </div>
                <div>
                    <input type="email" name="email" placeholder="..." required onChange={e => setEmail(e.target.value)}></input>
                </div>
                <div>
                    <label>Wachtwoord:</label>
                </div>
                <div>
                    <input type="password" name="password" placeholder="..." required onChange={e => setPassword(e.target.value)}></input>
                </div>
                <input type="submit" value="Log In"></input>
            </form>
        </div>
    )
}