import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import { MdArrowDropDown } from 'react-icons/md';
import './dashboard.css';

export default function Dashboard() {
    
    const navigate = useNavigate()
    const [allMerchantValue, setAllMerchantValue] = useState([]);
    const cookies = new Cookies();
    const handleLogOut = () => {
        cookies.remove('email')
        navigate('/')
    }
    const navigateToOverzicht = (merchantId) => {
        navigate(`/overzicht/${merchantId}`)
        // console.log(merchantId)
    }

    useEffect(() => {
        fetch(`https://dev.payments.techdogcloud.com/api/v1/merchants/getall`)
        .then(res => res.json())
        .then(data => setAllMerchantValue(data))
        .catch(err => console.log(err))
    }, [])
    // useEffect(() => {
    //     if(cookies.get('email') == null) {
    //         navigate("/")
    //     }
    // }, [cookies])



    return(
        <div>
            <button onClick={handleLogOut}>Log Uit</button>
            <div className="merchantButtonBox">
                <h1>Merchants</h1>
                {allMerchantValue.map(data => <button key={data.id} onClick={e => navigateToOverzicht(data.merchantId)}>{data.companyName}</button>)}
            </div>
        </div>
    )
}