 /* eslint-disable */ 
import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import Cookies from 'universal-cookie';
import "react-datepicker/dist/react-datepicker.css";
import { MdArrowDropDown } from 'react-icons/md';
import '../App.css'
import axios from "axios";

export default function Overzicht() {

    const today = new Date()
    const [merchantValue, setMerchantValue] = useState([]);
    const [allMerchantValue, setAllMerchantValue] = useState([]);
    const [priceAgreements, setPriceAgreements] = useState([]);
    const [defaultAgreements, setDefaultAgreements] = useState([]);
    const [sessionData, setSessionData] = useState([]);
    const [apiCode, setApiCode] = useState("");
    const [apiToken, setApiToken] = useState("");
    const [startDate, setStartDate] = useState(new Date(today.getFullYear(), today.getMonth()-1));
    const [endDate, setEndDate] = useState(new Date(today.getFullYear(), today.getMonth(), 0));
    const [startDatum, setStartDatum] = useState((startDate.getFullYear() + "-" + ((startDate.getMonth())+1) + "-" + startDate.getDate()));
    const [eindDatum, setEindDatum] = useState((endDate.getFullYear() + "-" + ((endDate.getMonth())+1) + "-" + endDate.getDate()));
    const [filterShow, setFilterShow] = useState("vorige maand");
    const navigate = useNavigate()
    const cookies = new Cookies();
    let countTransactionsHandTerminals = 0;
    let countAmountHandTerminals = 0;
    let countSecondTransactions = 0;
    let countSecondAmount = 0;
    let counts = [];
    let count2310duration = 0;
    (sessionData.filter(data => data.payment_profile_id == "2310").map(res => (count2310duration += parseInt(res.duration))));
    let count2310 = 0;
    (sessionData.filter(data => data.payment_profile_id == "2310").map(res => (count2310 += parseFloat(res.amount))));

    let count2313duration = 0;
    (sessionData.filter(data => data.payment_profile_id == "2313").map(res => (count2313duration += parseInt(res.duration))));
    let count2313 = 0;
    (sessionData.filter(data => data.payment_profile_id == "2313").map(res => (count2313 += parseFloat(res.amount))));

    let count1639duration = 0;
    (sessionData.filter(data => data.payment_profile_id == "1639").map(res => (count1639duration += parseInt(res.transactions))));
    let count1639 = 0;
    (sessionData.filter(data => data.payment_profile_id == "1639").map(res => (count1639 += parseFloat(res.amount))));

    let count10duration = 0;
    (sessionData.filter(data => data.payment_profile_id == "10").map(res => (count10duration += parseInt(res.transactions))));
    let count10 = 0;
    (sessionData.filter(data => data.payment_profile_id == "10").map(res => (count10 += parseFloat(res.amount))));


    // (sessionData.filter(data => data.payment_profile_id == "1639").map(res => console.log(res)));
    // (sessionData.filter(data => data.payment_profile_id == "2310").map(res => console.log(res)));


    const params = useParams();
    const merchantId = params.merchantId;


    useEffect(() => {
        setStartDatum((startDate.getFullYear() + "-" + ((startDate.getMonth())+1) + "-" + (startDate.getDate())))
        setEindDatum((endDate.getFullYear() + "-" + ((endDate.getMonth())+1) + "-" + (endDate.getDate())))
    }, [startDate, endDate])

    useEffect(() => {
        {allMerchantValue != 'undefined' ? 
        setApiCode(allMerchantValue.filter(data => data.merchantId == merchantId).map(data => data.API_code)) : ""}
        {allMerchantValue != 'undefined' ? 
        setApiToken(allMerchantValue.filter(data => data.merchantId == merchantId).map(data => data.API_token)) : ""}
    }, [allMerchantValue])

    useEffect(() => {
        axios.get(`https://dev.payments.techdogcloud.com/api/v1/merchants/${merchantId}/${startDatum}/${eindDatum}`)
        .then(data => setMerchantValue(data.data))
        .catch(err => console.log(err))
    }, [merchantId, startDatum, eindDatum])

    useEffect(() => {
        axios.get(`https://dev.payments.techdogcloud.com/api/v1/merchants/getall`)
        .then(data => setAllMerchantValue(data.data))
        .catch(err => console.log(err))
    }, [merchantId, startDatum, eindDatum])

    useEffect(() => {
        axios.get(`https://dev.payments.techdogcloud.com/api/v1/merchants/priceagreements/custom/retrieve/${merchantId}`)
        .then(data => setPriceAgreements(data.data))
        .catch(err => console.log(err))
    }, [merchantId])

    useEffect(() => {
        axios(`https://dev.payments.techdogcloud.com/api/v1/merchants/defaultpriceagreements`)
        .then(data => setDefaultAgreements(data.data))
        .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        // console.log(apiCode)
        // console.log(apiToken)
        {apiCode != "" && apiToken != "" ? 
        (axios.get(`https://dev.payments.techdogcloud.com/api/v1/statistics/${startDatum}/${eindDatum}/${apiCode}/${apiToken}`)
        .then(data => setSessionData(Object.values(data.data[0].arrStatsData)))
        .then(console.log(`https://dev.payments.techdogcloud.com/api/v1/statistics/${startDatum}/${eindDatum}/${apiCode}/${apiToken}`))
        // .then(console.log(apiCode + apiToken))
        .catch(err => console.log(err)))
         : setSessionData([])}
    }, [startDatum, eindDatum, apiCode, apiToken])
  
    // useEffect(() => {
    //     if(cookies.length == 0) {
    //         navigate("/")
    //     }
    // }, [cookies])

    function todayButton() {
        setStartDate(new Date())
        setEndDate(new Date())
        setFilterShow("vandaag")
    }

    function thisWeek() {
        setStartDate(new Date())
        setEndDate(new Date())
        setFilterShow("huidige week")
    }

    function lastWeek() {
        setStartDate(new Date())
        setEndDate(new Date())
        setFilterShow("vorige week")
    }

    function yesterdayButton() {
        setStartDate(new Date(new Date().setDate(new Date().getDate() - 1)))
        setEndDate(new Date(new Date().setDate(new Date().getDate() - 1)))
        setFilterShow("gisteren")
    }

    function thisMonth() {
        setStartDate(new Date(today.getFullYear(), today.getMonth()))
        setEndDate(new Date(today.getFullYear(), today.getMonth()+1, -1))
        setFilterShow("huidige maand")
    }

    function lastMonth() {
        setStartDate(new Date(today.getFullYear(), today.getMonth()-1))
        setEndDate(new Date(today.getFullYear(), today.getMonth(), 0))
        setFilterShow("vorige maand")
    }

    function thisYear() {
        setStartDate(new Date(today.getFullYear(), 0))
        setEndDate(new Date(today.getFullYear() + 1, 0, 0))
        setFilterShow("huidig jaar")
    }

    function lastYear() {
        setStartDate(new Date(today.getFullYear()-1, 0))
        setEndDate(new Date(today.getFullYear(), 0, 0))
        setFilterShow("vorig jaar")
    }


    // console.log(Object.keys(sessionData))
//    console.log(Object.values(sessionData))

    // useEffect(() => {
        sessionData.map(data => countTransactionsHandTerminals += parseInt(data.duration))
        // Object.values(sessionData).map(data => {console.log(countTransactionsHandTerminals)})
        sessionData.map(data => countAmountHandTerminals += parseFloat(data.amount))
        sessionData.filter(data => 
            data.payment_profile_id == "2310" ||
            data.payment_profile_id == "2313" ||
            // data.payment_profile_id == "1639" ||
            data.payment_profile_id == "10"
            ).map(data => countSecondTransactions += parseInt(data.duration))
        sessionData.filter(data => 
            data.payment_profile_id == "2310" || 
            data.payment_profile_id == "2313" ||
            // data.payment_profile_id == "1639" ||
            data.payment_profile_id == "10"
            ).map(data => countSecondAmount += parseFloat(data.amount))
        // sessionData.map(data => 
        //     // data[data.payment_profile_id] += data.amount
        // );
        // Object.values(sessionData).map(data => {console.log(countAmountHandTerminals)})
        // Object.values(sessionData).map(data => console.log("doei " + data))
        // console.log(sessionData)
    // }, [sessionData])

    
    {
    return(
        <div>
        {/* <div>{Object.values(sessionData).map(data => <h1 key={data.extra1}>{data.extra1}</h1>)}</div> */}
            <button onClick={e => navigate("/dashboard")}>Home</button>
            {allMerchantValue != 'undefined' ? allMerchantValue.filter(data => data.merchantId == merchantId).map(filtered => <h1 key={filtered.companyName} className="companyname">{filtered.companyName}</h1>) : ""}
            <nav className="navBarTransacties">
                <div className="dateSelect">
                    <DatePicker className="datepicker" selected={startDate} showYearDropdown selectsStart startDate={startDate} endDate={endDate} onChange={(date) => setStartDate(date) + setFilterShow("eigen gekozen datum")} dateFormat="dd-MM-yyyy" maxDate={endDate}/>
                    <label><p> t/m </p></label>
                    <DatePicker className="datepicker" selected={endDate} showYearDropdown selectsEnd startDate={startDate} endDate={endDate} onChange={(date) => setEndDate(date) + setFilterShow("eigen gekozen datum")} dateFormat="dd-MM-yyyy" minDate={startDate} />
                </div>
                <div>
                <div className="dropdownMerchants">
                    <div>
                        <button>Merchant<MdArrowDropDown/></button>
                        <div className="dropdownMerchants-content">
                            {allMerchantValue != 'undefined' ? allMerchantValue.map(data => <button key={data.merchantId} className="option"><a key={data.merchantId} className="optionValue" href={`/overzicht/${data.merchantId}`}>{data.companyName}</a></button>) : ""}
                        </div>
                    </div>
                </div>
                </div>
                <div>
                    Filter: {filterShow}
                </div>
                <div className="dateSelect">
                    <button onClick={todayButton}>Vandaag</button>
                    <button onClick={yesterdayButton}>Gisteren</button>
                <div className="dropdownDateSelect">
                    <div>
                        <button><MdArrowDropDown/></button>
                        <div className="dropdownDateSelect-content">
                            <button key={thisWeek} className="option" onClick={thisWeek}>Deze Week</button>
                            <button key={lastWeek} className="option" onClick={lastWeek}>Vorige Week</button>
                            <button key={thisMonth} className="option" onClick={thisMonth}>Deze Maand</button>
                            <button key={lastMonth} className="option" onClick={lastMonth}>Vorige Maand</button>
                            <button key={thisYear} className="option" onClick={thisYear}>Dit Jaar</button>
                            <button key={lastYear} className="option" onClick={lastYear}>Vorig Jaar</button>
                        </div>
                    </div>
                </div>
                </div>
            </nav>
            {merchantValue != 'undefined' ? merchantValue.map(value =>
                <div key={value.id}>
                    <h1 className="groupTitle">Terminals</h1>
                    <div className="mainBox">
                        {value.paymentMethods.map(data => 
                        <div key={data.id}>
                            {data.id != 2310 && data.id != 2313 ? (
                            <div key={data.id} className='sub-box'>
                                <div>
                                <div className="boxTitle"><img src="" alt="(logo)" /><p>{(defaultAgreements.filter(agree => agree.method_id == data.id).map(filtered => filtered.method_name) != '' ? (defaultAgreements.filter(agree => agree.method_id == data.id).map(filtered => filtered.method_name)) : (data.id + " Onbekende methode " + data.name) )}</p></div>
                                <div className="transactionData">    
                                    <div className="dataRow">
                                        <div>Transacties</div>
                                        <div>{data.transactions}</div>
                                    </div>
                                    <div className="dataRow">
                                        <div>Transactie kosten per stuk (excl. BTW)</div>
                                        <div>€ {(priceAgreements.filter(agree => agree.paymentMethodId == data.id).map(filtered => filtered.sellRate) != 0 ? (priceAgreements.filter(agree => agree.paymentMethodId == data.id).map(filtered => filtered.sellRate)) : (defaultAgreements.filter(agree => agree.method_id == data.id).map(filtered => filtered.sellrate_price)) )}</div>
                                    </div>
                                    <div className="dataRow">
                                        <div>Transactie kosten totaal (excl. BTW)</div>
                                        <div>€ {(data.transactions * (priceAgreements.filter(agree => agree.paymentMethodId == data.id).map(filtered => filtered.sellRate) != 0 ? (priceAgreements.filter(agree => agree.paymentMethodId == data.id).map(filtered => filtered.sellRate)) : (defaultAgreements.filter(agree => agree.method_id == data.id).map(filtered => filtered.sellrate_price)) )).toFixed(2)}</div>
                                    </div>
                                    <div className="dataRow">
                                        <div>Omzet (incl. BTW)</div>
                                        <div>€ {(data.turnover).toFixed(2)}</div>
                                    </div>
                                    <div className="dataRow">
                                        <div>Omzet kosten percentage (excl. BTW)</div>
                                        <div>% {(priceAgreements.filter(agree => agree.paymentMethodId == data.id).map(filtered => filtered.sellPercentage)) != 0 ? (priceAgreements.filter(agree => agree.paymentMethodId == data.id).map(filtered => filtered.sellPercentage.toFixed(2))) : (defaultAgreements.filter(agree => agree.method_id == data.id).map(filtered => filtered.sellrate_percentage))}</div>
                                    </div>
                                    <div className="dataRow">
                                        <div>Omzet kosten totaal (excl. BTW)</div>
                                        <div>€ {((priceAgreements.filter(agree => agree.paymentMethodId == data.id).map(filtered => filtered.sellPercentage)) != 0 ? (priceAgreements.filter(agree => agree.paymentMethodId == data.id).map(filtered => filtered.sellPercentage.toFixed(2))) : (defaultAgreements.filter(agree => agree.method_id == data.id).map(filtered => ((filtered.sellrate_percentage * data.turnover) / 100).toFixed(2))))}</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            ) : (null)}
                            </div>
                        )}
                </div>
            </div>
            ) : ""}
            <div className="mainBox">
                <h1 className="groupTitle">Handmatige Terminals</h1>
                    {
                        <div>
                            <div className="mainBox">
                                <div className="sub-box">
                                    <div className="boxTitle">Handmatige Terminals</div>
                                    <div className="dataRow">
                                        <div>Transacties</div>
                                        <div>{countTransactionsHandTerminals}</div>
                                    </div>
                                    <div className="dataRow">
                                        <div>Amount</div>
                                        <div>€ {countAmountHandTerminals.toFixed(2)}</div>
                                    </div>
                                    <div className="dataRow">
                                        <div>Transacties (bekend)</div>
                                        <div>{countSecondTransactions}</div>
                                    </div>
                                    <div className="dataRow">
                                        <div>Amount (bekend)</div>
                                        <div>€ {countSecondAmount.toFixed(2)}</div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                            // console.log(ppi.duration)
                            // <div key={ppi.id} className="sub-box">
                            //     <div className="boxTitle">{ppi.payment_profile_id}</div>
                            //     <div className="dataRow">
                            //         <div>Transacties</div>
                            //         <div>{ppi.duration}</div>
                            //     </div>
                            // </div>
                        
                    }
                    {count2310 != 0 && count2310duration != 0 ? (
                    <div className="sub-box">
                        <div className="boxTitle">2310 - DEBIT (Terminal) - Maestro PIN NL (handmatig)</div>
                        <div className="dataRow">
                            <div>Transacties</div>
                            <div>{count2310duration.toFixed()}</div>
                        </div>
                        <div className="dataRow">
                            <div>Amount</div>
                            <div>€ {count2310.toFixed(2)}</div>
                        </div>
                    </div>) : ""}

                    {count2313 != 0 && count2313duration != 0 ? (
                    <div className="sub-box">
                        <div className="boxTitle">2313 - DEBIT (Terminal) - PIN (handmatig) V PAY NL</div>
                        <div className="dataRow">
                            <div>Transacties</div>
                            <div>{count2313duration.toFixed()}</div>
                        </div>
                        <div className="dataRow">
                            <div>Amount</div>
                            <div>€ {count2313.toFixed(2)}</div>
                        </div>
                    </div>) : ""}


                    {count1639 != 0 && count1639duration != 0 ? (
                    <div className="sub-box">
                        <div className="boxTitle">1639 - onbekende betaalmethode</div>
                        <div className="dataRow">
                            <div>Transacties</div>
                            <div>{count1639duration.toFixed()}</div>
                        </div>
                        <div className="dataRow">
                            <div>Amount</div>
                            <div>€ {count1639.toFixed(2)}</div>
                        </div>
                    </div>) : ""}
                    {sessionData.filter(data => data.payment_profile_id == "1639").map(data => console.log(data))}


                    {count10 != 0 && count10duration != 0 ? (
                    <div className="sub-box">
                        <div className="boxTitle">10 - iDeal</div>
                        <div className="dataRow">
                            <div>Transacties</div>
                            <div>{count10duration.toFixed()}</div>
                        </div>
                        <div className="dataRow">
                            <div>Amount</div>
                            <div>€ {count10.toFixed(2)}</div>
                        </div>
                    </div>) : ""}
                    {/* {sessionData.map(data => data.payment_profile_id + " - ")} */}
                    {/* {sessionData.map(data =>
                            counts[data.payment_profile_id] = data.amount
                        // sessionData.filter(data2 => data2.payment_profile_id != data.payment_profile_id).map(data3 => data3.payment_profile_id + " - ")
                    )}
                    {console.log(counts)} */}
            </div>
        </div>
    )
    }
}