import Overzicht from "./components/overzicht";
import Login from "./components/Login/login";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import Dashboard from "./components/dashboard/dashboard";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />}/>
        <Route path="/dashboard" element={<Dashboard />}/>
        <Route path="/overzicht/:merchantId" element={<Overzicht />}/>
      </Routes>
    </Router>
  )
}

export default App;